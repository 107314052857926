import { defineStore } from 'pinia'
import { computed, ref } from 'vue'
import { formatAddress } from '@artmizu/utils'
import type { Address } from '~/type/Address'

export const useAddressStore = defineStore('address', () => {
  const current = ref<Address>()
  const isCurrentExist = computed(() => !!current.value)
  const formatted = computed(() => current.value && formatAddress(current.value))

  function set(payload: Address) {
    current.value = payload
  }

  function reset() {
    current.value = undefined
  }

  return { current, isCurrentExist, formatted, set, reset }
}, {
  persist: true,
  share: {
    enable: true,
  },
})
