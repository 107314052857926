<script setup lang="ts">
import { storeToRefs } from 'pinia'
import { formatPhoneAndMask } from '@artmizu/utils'
import { useAddressStore } from '~/store/address'
import { useChooseAddressStore } from '~/store/modal/chooseAddress'
import { useStoriesStore } from '~/store/modal/stories'
import { useClearBasketStore } from '~~/store/modal/clearBasket'
import type { Address } from '~/type/Address'
import { useCartStore } from '~/store/cart'
import { useOrderReviewModal } from '~/store/modal/orderReview'
import { useDeliveryTimeSelectModalStore } from '~/store/modal/deliveryTimeSelect'
import { useSearchMobileStore } from '~/store/modal/searchMobile'
import { useSaveCardModal } from '~~/store/modal/saveCard'
import { useSocialsStore } from '~/store/socials'
import { useSupportModalStore } from '~/store/modal/support'
import { useAuthStore } from '~/store/modal/auth'
import { useOrderModalStore } from '~/store/modal/order'

const { $emitter, $api } = useNuxtApp()

const { enable: enableStories, disable: disableStories } = useStoriesStore()
const { active: isShowOrder } = storeToRefs(useOrderModalStore())
const { active: isStoriesActive, storyID } = storeToRefs(useStoriesStore())
const { active: isChooseAddressModalActive } = storeToRefs(useChooseAddressStore())
const { current } = storeToRefs(useAddressStore())
const { cartChanged, changedList, serviceType, deliveryTimeData } = storeToRefs(useCartStore())
const deliveryTimeSelectModalStore = useDeliveryTimeSelectModalStore()
const orderReviewModal = useOrderReviewModal()
const searchMobileStore = useSearchMobileStore()
const saveCardModal = useSaveCardModal()
const clearBasket = useClearBasketStore()
const socials = useSocialsStore()
const { active } = storeToRefs(useAuthStore())
const supportModalStore = useSupportModalStore()

const storiesParam = computed(() => {
  return {
    groupID: storyID.value,
  }
})

const storiesDataEntity = await useActionEntity($api.story.getByGroup, {
  key: 'stories',
  params: storiesParam,
})

/* Показываем пользователям впервые зашедшим в даркстор модалку сторис */
const isShowStories = useCookie('isShowStories', {
  expires: new Date(),
  maxAge: 60 * 60 * 24 * 360,
  path: '/',
})

if (!isShowStories.value) {
  isShowStories.value = 'true'
  enableStories('FIRST_LAUNCH')
}

const supportItems = [
  {
    title: `Позвонить: ${formatPhoneAndMask(socials.phone)}`,
    href: `tel:${formatPhoneAndMask(socials.phone)}`,
  },
  {
    title: 'Написать в WhatsApp',
    href: socials.whatsApp,
  },
  {
    title: 'Написать в Telegram',
    href: socials.telegram,
  },
]

onMounted(() => {
  getStory()
})

watch(() => isStoriesActive.value, () => {
  getStory()
})

/* Делаем запрос сторис только при условии активации модального окна сториc
*/
function getStory() {
  if (isStoriesActive.value)
    storiesDataEntity.request()
}

function onAddressSelect(val: Address) {
  if (current.value?.street !== val.street && current.value?.house !== val.house)
    $emitter.emit('user-select-another-address', 'userSelectAnotherAddress')
}
</script>

<template>
  <div>
    <!-- Окно авторизации -->
    <KeepAlive>
      <AuthModal v-if="active" />
    </KeepAlive>
    <!-- Окно товара -->
    <ProductModal />
    <!-- Окно смены проекта -->
    <ChooseProject />
    <!-- Окно заказа -->
    <KeepAlive>
      <OrderStatusModal v-if="isShowOrder" />
    </KeepAlive>
    <!-- Окно подтверждения -->
    <ConfirmModal />
    <!-- Окно подтверждения удаления карты -->
    <ConfirmRemovePaymentCardModal />
    <!-- Окно сторис -->
    <Stories
      v-if="storiesDataEntity.success.value && storiesDataEntity.data.value"
      :stories="storiesDataEntity.data.value"
      :active="isStoriesActive"
      :error="storiesDataEntity.error.value"
      @close="disableStories"
    />
    <ServiceModal v-if="serviceType" :type="serviceType" />
    <CartChangedModal v-if="cartChanged" :product-list="changedList" />
    <!-- Окно выбора адреса -->
    <ChooseAddressMap v-if="isChooseAddressModalActive" @select-address="onAddressSelect" />
    <!-- оконо удаления товаров из корзины -->
    <ClearBasketModal :active="clearBasket.active" @close="clearBasket.disable()" />
    <OrderReviewModal v-if="orderReviewModal.active" />
    <!-- окно отображения состояния в ответ на действие пользователя -->
    <ActionStatusModal />
    <SearchMobileModal v-if="searchMobileStore.active" />
    <!-- окно подтверждения сохранения карты, которой оплачен заказ -->
    <SaveCardModal v-if="saveCardModal.active" />
    <!-- окно для получения уведомления о доступности адреса для доставки -->
    <DeliveryAvailableModal />
    <SupportModal
      :items="supportItems"
      :show="supportModalStore.active"
      @overlay-close="supportModalStore.disable"
    />
    <CCDeliveryDelayedTimeSelectModal v-if="deliveryTimeData && deliveryTimeSelectModalStore.active" />
  </div>
</template>
