import { format } from 'date-fns'

/**
 * Возвращает время из секунд в формате 'HH:mm:ss'
 * @param seconds количество секунд
 */
export function formatTimeFromSeconds(seconds: number) {
  const date = new Date(1970, 0, 1, 0, 0, seconds)

  return format(date, 'HH:mm:ss')
}
