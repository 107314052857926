import { defineStore } from 'pinia'
import type { Order } from '~/type/order/Order'
import { useUserStore } from '~/store/user'
import { useAuthStore } from '~/store/modal/auth'

export const useOrderModalStore = defineStore('orderModal', () => {
  const { $api, $error } = useNuxtApp()
  const route = useRoute()
  const router = useRouter()
  const userStore = useUserStore()
  const authStore = useAuthStore()
  const query = computed(() => route.query)
  const active = ref(false)
  const onAccess = ref<Callback>()
  const data: Ref<null | Order> = ref(null)

  function addToURL(id: string) {
    const currentQuery = query.value
    void router.push({ query: { ...currentQuery, 'order-modal': id } })
  }

  function removeFromURL() {
    // Удаляем только наш параметр из урла
    const cleanQuery = Object.assign({}, query.value)
    delete cleanQuery['order-modal']
    void router.replace({ query: cleanQuery })
  }

  async function getOrderByID() {
    try {
      const result = await $api.order.getByID(query.value['order-modal'] as string)
      // Если с API пришли данные по заказу, вызываем модальное окно
      if (result)
        enable({ data: result })
    }
    catch {
      new $error.simple({
        message: 'Ошибка при получении информации о заказе',
      })
    }
  }

  /*
    Проверяем, есть ли у нас в урле айдишник заказа (обычно такое бывает после редиректа со страницы оплаты)
    Если есть и пользователь авторизован, делаем запрос за заказом (чтобы получить данные о нём и отобразить их в модалке)
    Если в урле есть айдишник заказа, но пользователь не авторизован, сначала авторизуем его, а потом получаем инфу о заказе
  */
  if (query.value?.['order-modal'] && userStore.isUser) {
    void getOrderByID()
  }
  else if (query.value?.['order-modal'] && userStore.isGuest) {
    authStore.enable({
      onComplete: () => {
        void getOrderByID()
      },
    })
  }

  function enable(payload: { onAccess?: noop; data: Order }) {
    active.value = true
    if (payload.onAccess && onAccess.value)
      onAccess.value.callback = payload.onAccess

    data.value = payload.data
    // Добавляем в URL параметры для окна заказа
    addToURL(data.value.id)
  }

  function disable() {
    active.value = false
    // Удаляем из URL параметры относящиеся к окну заказа
    removeFromURL()
  }

  function setCancelledStatus() {
    if (data.value) {
      data.value.status.type = 'cancelled'
      data.value.status.name = 'Заказ отменен'
      data.value.isDelay = false
      data.value.payResult = undefined
    }
  }

  function set(payload: Order) {
    data.value = payload
  }

  return {
    active,
    onAccess,
    data,
    enable,
    disable,
    setCancelledStatus,
    set,
  }
})
