import { useCartStore } from '~/store/cart'
import { useDeliveryStore } from '~/store/delivery'
import type { DeliveryType } from '~/type/DeliveryTime'

export function checkDeliveryType() {
  const deliveryStore = useDeliveryStore()
  const cartStore = useCartStore()

  const hasValue = computed(() => ['delayed', 'express'].some((field: string) => cartStore.deliveryTimeData[field as DeliveryType]))

  if (!hasValue.value)
    deliveryStore.setDeliveryType()
  else if (cartStore.deliveryTimeData.delayed && !cartStore.deliveryTimeData.express)
    deliveryStore.setDeliveryType('delayed')
}
