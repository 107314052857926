import { default as indexFM8yz9M7giMeta } from "/builds/darkstore/darkstore-web/pages/about/privacy/index.vue?macro=true";
import { default as index6y5oJWlwzzMeta } from "/builds/darkstore/darkstore-web/pages/about/terms-of-use/index.vue?macro=true";
import { default as indexRPk3liEh7wMeta } from "/builds/darkstore/darkstore-web/pages/cart/index.vue?macro=true";
import { default as index1hzrZ4ecPtMeta } from "/builds/darkstore/darkstore-web/pages/catalog/index.vue?macro=true";
import { default as indexNB9eajCyX7Meta } from "/builds/darkstore/darkstore-web/pages/favorite/index.vue?macro=true";
import { default as indexkrjj3MibmRMeta } from "/builds/darkstore/darkstore-web/pages/index.vue?macro=true";
import { default as indexBAEZN48NyDMeta } from "/builds/darkstore/darkstore-web/pages/new-product/index.vue?macro=true";
import { default as indexPRM5A8TnivMeta } from "/builds/darkstore/darkstore-web/pages/previously-order/index.vue?macro=true";
import { default as _idEe7grPmLRXMeta } from "/builds/darkstore/darkstore-web/pages/product/_id.vue?macro=true";
import { default as indexwcs6CWWjyyMeta } from "/builds/darkstore/darkstore-web/pages/promo/index.vue?macro=true";
import { default as indexKgzGgSSuUFMeta } from "/builds/darkstore/darkstore-web/pages/recipes/[id]/index.vue?macro=true";
import { default as indexTt3qv4flKxMeta } from "/builds/darkstore/darkstore-web/pages/sale/index.vue?macro=true";
import { default as indexKn3KK00oXHMeta } from "/builds/darkstore/darkstore-web/pages/search/index.vue?macro=true";
import { default as indexeP9LbrYPnBMeta } from "/builds/darkstore/darkstore-web/pages/selection/[id]/index.vue?macro=true";
import { default as testtZD7VqtZcUMeta } from "/builds/darkstore/darkstore-web/pages/test.vue?macro=true";
import { default as indexzz6qvN7odSMeta } from "/builds/darkstore/darkstore-web/pages/user/card/index.vue?macro=true";
import { default as indexuUwtlvpVYxMeta } from "/builds/darkstore/darkstore-web/pages/user/order/[id]/index.vue?macro=true";
import { default as indexJWC8tnf0NEMeta } from "/builds/darkstore/darkstore-web/pages/user/order/index.vue?macro=true";
import { default as index6cP6M501LLMeta } from "/builds/darkstore/darkstore-web/pages/user/question/index.vue?macro=true";
import { default as indexoLHrwJHTj6Meta } from "/builds/darkstore/darkstore-web/pages/user/setting/index.vue?macro=true";
import { default as userkpYkZ9yJ7HMeta } from "/builds/darkstore/darkstore-web/pages/user.vue?macro=true";
export default [
  {
    name: indexFM8yz9M7giMeta?.name ?? "about-privacy",
    path: indexFM8yz9M7giMeta?.path ?? "/about/privacy",
    meta: indexFM8yz9M7giMeta || {},
    alias: indexFM8yz9M7giMeta?.alias || [],
    redirect: indexFM8yz9M7giMeta?.redirect,
    component: () => import("/builds/darkstore/darkstore-web/pages/about/privacy/index.vue").then(m => m.default || m)
  },
  {
    name: index6y5oJWlwzzMeta?.name ?? "about-terms-of-use",
    path: index6y5oJWlwzzMeta?.path ?? "/about/terms-of-use",
    meta: index6y5oJWlwzzMeta || {},
    alias: index6y5oJWlwzzMeta?.alias || [],
    redirect: index6y5oJWlwzzMeta?.redirect,
    component: () => import("/builds/darkstore/darkstore-web/pages/about/terms-of-use/index.vue").then(m => m.default || m)
  },
  {
    name: indexRPk3liEh7wMeta?.name ?? "cart",
    path: indexRPk3liEh7wMeta?.path ?? "/cart",
    meta: indexRPk3liEh7wMeta || {},
    alias: indexRPk3liEh7wMeta?.alias || [],
    redirect: indexRPk3liEh7wMeta?.redirect,
    component: () => import("/builds/darkstore/darkstore-web/pages/cart/index.vue").then(m => m.default || m)
  },
  {
    name: index1hzrZ4ecPtMeta?.name ?? "catalog",
    path: index1hzrZ4ecPtMeta?.path ?? "/catalog",
    meta: index1hzrZ4ecPtMeta || {},
    alias: index1hzrZ4ecPtMeta?.alias || [],
    redirect: index1hzrZ4ecPtMeta?.redirect,
    component: () => import("/builds/darkstore/darkstore-web/pages/catalog/index.vue").then(m => m.default || m)
  },
  {
    name: indexNB9eajCyX7Meta?.name ?? "favorite",
    path: indexNB9eajCyX7Meta?.path ?? "/favorite",
    meta: indexNB9eajCyX7Meta || {},
    alias: indexNB9eajCyX7Meta?.alias || [],
    redirect: indexNB9eajCyX7Meta?.redirect,
    component: () => import("/builds/darkstore/darkstore-web/pages/favorite/index.vue").then(m => m.default || m)
  },
  {
    name: indexkrjj3MibmRMeta?.name ?? "index",
    path: indexkrjj3MibmRMeta?.path ?? "/",
    meta: indexkrjj3MibmRMeta || {},
    alias: indexkrjj3MibmRMeta?.alias || [],
    redirect: indexkrjj3MibmRMeta?.redirect,
    component: () => import("/builds/darkstore/darkstore-web/pages/index.vue").then(m => m.default || m)
  },
  {
    name: indexBAEZN48NyDMeta?.name ?? "new-product",
    path: indexBAEZN48NyDMeta?.path ?? "/new-product",
    meta: indexBAEZN48NyDMeta || {},
    alias: indexBAEZN48NyDMeta?.alias || [],
    redirect: indexBAEZN48NyDMeta?.redirect,
    component: () => import("/builds/darkstore/darkstore-web/pages/new-product/index.vue").then(m => m.default || m)
  },
  {
    name: indexPRM5A8TnivMeta?.name ?? "previously-order",
    path: indexPRM5A8TnivMeta?.path ?? "/previously-order",
    meta: indexPRM5A8TnivMeta || {},
    alias: indexPRM5A8TnivMeta?.alias || [],
    redirect: indexPRM5A8TnivMeta?.redirect,
    component: () => import("/builds/darkstore/darkstore-web/pages/previously-order/index.vue").then(m => m.default || m)
  },
  {
    name: _idEe7grPmLRXMeta?.name ?? "product-_id",
    path: _idEe7grPmLRXMeta?.path ?? "/product/_id",
    meta: _idEe7grPmLRXMeta || {},
    alias: _idEe7grPmLRXMeta?.alias || [],
    redirect: _idEe7grPmLRXMeta?.redirect,
    component: () => import("/builds/darkstore/darkstore-web/pages/product/_id.vue").then(m => m.default || m)
  },
  {
    name: indexwcs6CWWjyyMeta?.name ?? "promo",
    path: indexwcs6CWWjyyMeta?.path ?? "/promo",
    meta: indexwcs6CWWjyyMeta || {},
    alias: indexwcs6CWWjyyMeta?.alias || [],
    redirect: indexwcs6CWWjyyMeta?.redirect,
    component: () => import("/builds/darkstore/darkstore-web/pages/promo/index.vue").then(m => m.default || m)
  },
  {
    name: indexKgzGgSSuUFMeta?.name ?? "recipes-id",
    path: indexKgzGgSSuUFMeta?.path ?? "/recipes/:id()",
    meta: indexKgzGgSSuUFMeta || {},
    alias: indexKgzGgSSuUFMeta?.alias || [],
    redirect: indexKgzGgSSuUFMeta?.redirect,
    component: () => import("/builds/darkstore/darkstore-web/pages/recipes/[id]/index.vue").then(m => m.default || m)
  },
  {
    name: indexTt3qv4flKxMeta?.name ?? "sale",
    path: indexTt3qv4flKxMeta?.path ?? "/sale",
    meta: indexTt3qv4flKxMeta || {},
    alias: indexTt3qv4flKxMeta?.alias || [],
    redirect: indexTt3qv4flKxMeta?.redirect,
    component: () => import("/builds/darkstore/darkstore-web/pages/sale/index.vue").then(m => m.default || m)
  },
  {
    name: indexKn3KK00oXHMeta?.name ?? "search",
    path: indexKn3KK00oXHMeta?.path ?? "/search",
    meta: indexKn3KK00oXHMeta || {},
    alias: indexKn3KK00oXHMeta?.alias || [],
    redirect: indexKn3KK00oXHMeta?.redirect,
    component: () => import("/builds/darkstore/darkstore-web/pages/search/index.vue").then(m => m.default || m)
  },
  {
    name: indexeP9LbrYPnBMeta?.name ?? "selection-id",
    path: indexeP9LbrYPnBMeta?.path ?? "/selection/:id()",
    meta: indexeP9LbrYPnBMeta || {},
    alias: indexeP9LbrYPnBMeta?.alias || [],
    redirect: indexeP9LbrYPnBMeta?.redirect,
    component: () => import("/builds/darkstore/darkstore-web/pages/selection/[id]/index.vue").then(m => m.default || m)
  },
  {
    name: testtZD7VqtZcUMeta?.name ?? "test",
    path: testtZD7VqtZcUMeta?.path ?? "/test",
    meta: testtZD7VqtZcUMeta || {},
    alias: testtZD7VqtZcUMeta?.alias || [],
    redirect: testtZD7VqtZcUMeta?.redirect,
    component: () => import("/builds/darkstore/darkstore-web/pages/test.vue").then(m => m.default || m)
  },
  {
    name: userkpYkZ9yJ7HMeta?.name ?? "user",
    path: userkpYkZ9yJ7HMeta?.path ?? "/user",
    meta: userkpYkZ9yJ7HMeta || {},
    alias: userkpYkZ9yJ7HMeta?.alias || [],
    redirect: userkpYkZ9yJ7HMeta?.redirect,
    component: () => import("/builds/darkstore/darkstore-web/pages/user.vue").then(m => m.default || m),
    children: [
  {
    name: indexzz6qvN7odSMeta?.name ?? "user-card",
    path: indexzz6qvN7odSMeta?.path ?? "card",
    meta: indexzz6qvN7odSMeta || {},
    alias: indexzz6qvN7odSMeta?.alias || [],
    redirect: indexzz6qvN7odSMeta?.redirect,
    component: () => import("/builds/darkstore/darkstore-web/pages/user/card/index.vue").then(m => m.default || m)
  },
  {
    name: indexuUwtlvpVYxMeta?.name ?? "user-order-id",
    path: indexuUwtlvpVYxMeta?.path ?? "order/:id()",
    meta: indexuUwtlvpVYxMeta || {},
    alias: indexuUwtlvpVYxMeta?.alias || [],
    redirect: indexuUwtlvpVYxMeta?.redirect,
    component: () => import("/builds/darkstore/darkstore-web/pages/user/order/[id]/index.vue").then(m => m.default || m)
  },
  {
    name: indexJWC8tnf0NEMeta?.name ?? "user-order",
    path: indexJWC8tnf0NEMeta?.path ?? "order",
    meta: indexJWC8tnf0NEMeta || {},
    alias: indexJWC8tnf0NEMeta?.alias || [],
    redirect: indexJWC8tnf0NEMeta?.redirect,
    component: () => import("/builds/darkstore/darkstore-web/pages/user/order/index.vue").then(m => m.default || m)
  },
  {
    name: index6cP6M501LLMeta?.name ?? "user-question",
    path: index6cP6M501LLMeta?.path ?? "question",
    meta: index6cP6M501LLMeta || {},
    alias: index6cP6M501LLMeta?.alias || [],
    redirect: index6cP6M501LLMeta?.redirect,
    component: () => import("/builds/darkstore/darkstore-web/pages/user/question/index.vue").then(m => m.default || m)
  },
  {
    name: indexoLHrwJHTj6Meta?.name ?? "user-setting",
    path: indexoLHrwJHTj6Meta?.path ?? "setting",
    meta: indexoLHrwJHTj6Meta || {},
    alias: indexoLHrwJHTj6Meta?.alias || [],
    redirect: indexoLHrwJHTj6Meta?.redirect,
    component: () => import("/builds/darkstore/darkstore-web/pages/user/setting/index.vue").then(m => m.default || m)
  }
]
  },
  {
    name: _idEe7grPmLRXMeta?.name ?? "product-page",
    path: _idEe7grPmLRXMeta?.path ?? "/catalog/:pathMatch(.*)/:id(\\d+)",
    meta: _idEe7grPmLRXMeta || {},
    alias: _idEe7grPmLRXMeta?.alias || [],
    redirect: _idEe7grPmLRXMeta?.redirect,
    component: () => import("/builds/darkstore/darkstore-web/pages/product/_id.vue").then(m => m.default || m)
  },
  {
    name: index1hzrZ4ecPtMeta?.name ?? "catalog-route",
    path: index1hzrZ4ecPtMeta?.path ?? "/catalog/:pathMatch(.*)",
    meta: index1hzrZ4ecPtMeta || {},
    alias: index1hzrZ4ecPtMeta?.alias || [],
    redirect: index1hzrZ4ecPtMeta?.redirect,
    component: () => import("/builds/darkstore/darkstore-web/pages/catalog/index.vue").then(m => m.default || m)
  },
  {
    name: indexkrjj3MibmRMeta?.name ?? "payment-complete",
    path: indexkrjj3MibmRMeta?.path ?? "/payment-complete/",
    meta: indexkrjj3MibmRMeta || {},
    alias: indexkrjj3MibmRMeta?.alias || [],
    redirect: indexkrjj3MibmRMeta?.redirect,
    component: () => import("/builds/darkstore/darkstore-web/pages/index.vue").then(m => m.default || m)
  }
]