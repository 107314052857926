import { useAddressStore } from '~/store/address'

/**
 * Хелпер для привязки адреса к токену пользователя
 * Достаточно сделать запрос, бэк сам автоматически привяжет
 */
export async function checkBindingAddress() {
  const { $api, $error } = useNuxtApp()
  const addressStore = useAddressStore()

  /**
   * Если адрес не выбран, то не делаем запрос на привязку.
   * Это связано с тем, что при оформлении заказа пользователь всплывает окно выбора адреса,
   * и привязка произойдёт автоматически.
   */
  if (!addressStore.current) {
    return true
  }

  else {
    try {
      await $api.map.setAddress(addressStore.current)
    }

    catch (e) {
      new $error.simple({
        name: 'Ошибка привязки адреса',
        description: 'Ошибка привязки адреса, пожалуйста, обновите страницу',
        message: 'Ошибка привязки адреса, пожалуйста, обновите страницу',
      })
    }
  }
}
