/**
* Добавляем в конец ссылки слеш / для seo оптимизации
* @example
* return '/catalog/sladkoye/'
* appendForwardSlash('/catalog/sladkoye')
*
* return '/cards/'
* appendForwardSlash('/cards/')
*
* return undefined
* appendForwardSlash(undefined)
*/

export function appendForwardSlash(link?: string) {
  if (link === undefined || link?.endsWith('/'))
    return link

  return `${link}/`
}
