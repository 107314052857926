import { storeToRefs } from 'pinia'
import checkCartChanges from './checkCartChanges'
import { checkBindingAddress } from './checkBindingAddress'
import { useTokenStore } from '~/store/token'
import { useUserStore } from '~/store/user'
import { useFavoriteStore } from '~/store/favorite'
import { useCartStore } from '~/store/cart'
import { useDeliveryStore } from '~/store/delivery'

export default async function ({ response }: { response: { [key: string]: any } }) {
  const config = useRuntimeConfig()
  const { $api, $error } = useNuxtApp()

  const { deliveryType } = storeToRefs(useDeliveryStore())
  const { set: setToken } = useTokenStore()
  const { set: setData } = useUserStore()
  const { setList: setFavoriteList } = useFavoriteStore()
  const cartStore = useCartStore()

  setToken(response.token)
  setData(response.user)
  setFavoriteList({ list: response.favorite })

  const cookieAccessToken = useCookie('user-access-token', {
    domain: config.public.crossAuthDomain,
    maxAge: 60 * 60 * 24 * 30,
  })

  const guestCart = cartStore.state
  const data = await $api.order.getOrderData()

  const mergedCart = {
    ...guestCart,
    ...data.product,
  }

  if (guestCart && Object.keys(mergedCart).length > 0) {
    try {
      const { result } = await $api.cart.sync({
        deliveryType: deliveryType.value,
        cart: Object.values(mergedCart),
      })
      cartStore.setCart(result)
    }
    catch (e) {
      new $error.simple({
        name: 'Ошибка',
        message: 'Ошибка при синхронизации корзины',
      })
    }
  }

  cookieAccessToken.value = response.token.accessToken.value

  await checkBindingAddress()

  void checkCartChanges()
}
